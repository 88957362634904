// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// THIS IS MAKING jQuery AVAILABLE EVEN INSIDE Views FOLDER
// global.$ = require("jquery")

require("jquery")

$(document).ready(function() {
    console.log("doc ready")

    // cancel review result form
    $( document ).on( "click", ".review-assignment-form-cancel", function(e) {
        console.log('submit form');
        location.reload();
    });

    // submit review result form
    $( document ).on( "click", ".review-assignment-form-submit", function(e) {
        console.log('submit form');
        $(".new_review_result").submit();
    });

    // language
    $( document ).on( "click", ".delete-language-item", function(e) {
        e.preventDefault()
        console.log('delete language item');
        $(this).parent().parent().parent().parent().remove();
        return false
    });

    $( document ).on("click", ".add-language-item", function(e) {
        e.preventDefault()
        console.log('add language item');
        $.get('/language_item/new')
        return false
    });

    // english level
    $( document ).on( "click", ".delete-english-level-item", function(e) {
        e.preventDefault()
        console.log('delete english level item');
        $(this).parent().parent().parent().parent().remove();
        return false
    });

    $( document ).on("click", ".add-english-level-item", function(e) {
        e.preventDefault()
        console.log('add english level item');
        $.get('/english_level_item/new')
        return false
    });

    // phone num
    $( document ).on( "click", ".delete-phonenum-item", function(e) {
        e.preventDefault()
        console.log('delete phonenum item');
        $(this).parent().parent().parent().parent().remove();
        return false
    });

    $( document ).on("click", ".add-phonenum-item", function(e) {
        e.preventDefault()
        console.log('add phonenum item');
        $.get('/phonenum_item/new')
        return false
    });

    // email
    $( document ).on( "click", ".delete-email-item", function(e) {
        e.preventDefault()
        console.log('delete email item');
        $(this).parent().parent().parent().parent().remove();
        return false
    });

    $( document ).on("click", ".add-email-item", function(e) {
        e.preventDefault()
        console.log('add email item');
        $.get('/email_item/new')
        return false
    });

    // work history
    $( document ).on( "click", ".delete-work-history-item", function(e) {
        e.preventDefault()
        console.log('delete work history item');
        $(this).parent().parent().parent().parent().remove();
        return false
    });

    $( document ).on("click", ".add-work-history-item", function(e) {
        e.preventDefault()
        console.log('add work history item');
        $.get('/work_history_item/new')
        return false
    });

    // education history
    $( document ).on( "click", ".delete-education-history-item", function(e) {
        e.preventDefault()
        console.log('delete education history item');
        $(this).parent().parent().parent().parent().remove();
        return false
    });

    $( document ).on("click", ".add-education-history-item", function(e) {
        e.preventDefault()
        console.log('add education history item');
        $.get('/education_history_item/new')
        return false
    });
})


// Tailwind CSS
import "stylesheets/application"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"
